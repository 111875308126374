.head{
    background: rgb(174,219,238);
background: radial-gradient(circle, rgba(174,219,238,0.4543067226890757) 0%, rgba(255,255,255,1) 100%);
    
    .header{
        font-family: 'Pacifico', cursive !important;
      
    }
    .button{
        font-size: 20px;
        font-weight: bold;
        width: 10rem;
        height: 3rem;
        // color: rgb(248, 244, 244);
        border-width: 2px;
        background-color: black;
        transition: none;
        border-radius: 200px !important;
        
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
  
}
