.search-area{
    width: 29rem;
    height: 4.5rem;
    color: black;
    border-width: 2px;
    border-style: solid;
    background-color: white;
    transition: 0.3s background-color linear, 0.1s color linear;
    border-radius: 200px !important;
    
  
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 448px) {
            width: 22rem;
        
      }
    .input-border{
        border: none;
        outline: none;
        font-size: 20px;
        padding: 10px;
        @media (max-width: 448px) {
            font-size: 15px;
            padding: 2px;
        
      }
    }
    .notify-button{
        font-size: 20px;
        font-weight: bold;
        width: 10rem;
        height: 4rem;
        color: rgb(248, 244, 244);
        border-width: 2px;
        border-style: solid;
        background-color: black;
        transition: 0.3s background-color linear, 0.1s color linear;
        border-radius: 200px !important;
        
      
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media (max-width: 448px) {
            font-size: 16px;
            font-weight: bold;
            width: 8rem;
        
      }
    }
  
  }