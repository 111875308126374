.landing{
    min-height: 80vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
  

    background: rgb(174,219,238);
background: radial-gradient(circle, rgba(174,219,238,0.4543067226890757) 0%, rgba(255,255,255,1) 100%);
    


    .title{
        font-family: UberMove, UberMoveText, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
          font-size: 60px;
          font-weight: 720;
          line-height: 55px;
          
          transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
          color: currentColor;
          letter-spacing: 0;
          margin: 1;
          width: 100%;
          /* text-align: center; */
          /* margin-left: 4rem */
      }


    .myimage{
        transform:scaleX(-1) ;
        // height: 500px;
        // width: 500px;
        border-radius: 10%;

    //     @media (max-width: 448px) {
    //         margin-top: 50px;
    //         height: 400px;
    //         width: 400px;
    //         border-radius: 10%;
           
        
    //   }
    }
}