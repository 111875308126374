.comingSoon{
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: #cfc7c737;
    // background: rgb(193,203,204);
    // background: linear-gradient(90deg, rgba(193,203,204,1) 12%, rgba(110,110,128,1) 54%, rgba(98,102,99,1) 92%);

    background: rgb(174,219,238);
background: radial-gradient(circle, rgba(174,219,238,0.4543067226890757) 0%, rgba(255,255,255,1) 100%);
    


    .title{
        font-family: UberMove, UberMoveText, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
          font-size: 60px;
          font-weight: 720;
          line-height: 55px;
          
          transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
          color: currentColor;
          letter-spacing: 0;
          margin: 1;
          width: 100%;
          /* text-align: center; */
          /* margin-left: 4rem */
      }
      .button{
        font-size: 20px;
        font-weight: bold;
        width: 10rem;
        height: 3rem;
        // color: rgb(248, 244, 244);
        border-width: 2px;
        background-color: black;
        transition: none;
        border-radius: 200px !important;
        
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
  
}