@import "../setting.scss";
.jobboard {
  flex: 0 0 auto;
  height: 50vh;
  width: 300px;

  color: #e9ecef;
  border-radius: 10px;
  overflow: hidden;
  > * {
    background-color: #ebecf0;
    color: #333;
    padding: 0 8px;
  }
  header {
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase; /* Transform text to uppercase */
    // letter-spacing: 0.1em;
    // border-bottom: 2px solid #ced4da;
  }

  ul.ul-list {
    list-style: none;
    margin: auto;

    overflow-y: auto;
  }
}
