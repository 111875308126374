.login{
    min-height: 80vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
  

    background: rgb(174,219,238);
background: radial-gradient(circle, rgba(174,219,238,0.4543067226890757) 0%, rgba(255,255,255,1) 100%);
    
}