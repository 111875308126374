@import "../setting.scss";
.job-board {
  height: 100vh;
  width: 100vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  // gap: 10rem;
  color: #e9ecef;
  background-color: $board-bg-color;
  h1 {
    text-align: center;
  }
  .job-column {
    display: flex;
    gap: 15px;
    // margin: auto;
    align-items: center;
    justify-content: center;
  }
}
