.footer{
    // background: #cfc7c737;
    background: rgb(174,219,238);
background: radial-gradient(circle, rgba(174,219,238,0.4543067226890757) 0%, rgba(255,255,255,1) 100%);
    .info{
        font-weight: bold;
        text-decoration: underline;
  text-decoration-color: rgb(0, 0, 0);
        text-underline-offset: 5px;
        cursor: pointer;
    }
}