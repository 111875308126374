$board-bg-color: #0067a3;
$gap: 10px;
$column-footer-height: 36px;
$column-header-height: 36px;
$column-border-radius: 36px;

// screen

$screen-lg: 1201px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-phone: 480px;
$screen-ip6-down: "(max-width: 375px)";
